import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import RightIcon from '../../images/right.svg'
import authAxios from '../../helpers/axios'
import { toast } from 'react-toastify'
import { InfoToast } from '../../helpers/toasters'
import SearchInput from '../../components/SearchInput'
import { BOOKING_CANCELLED, BOOKING_INITIATED, BOOKING_ONGOING, LIMIT } from '../../helpers/constants'
import { getDateFormat, getTimeFormat, getValidDateFormat } from '../../helpers/utils'
import Status from '../../components/Status'

export default function Rides() {

    const [searchText,setSearchText] = useState('')
    const [rides,setRides] = useState([])
    const [showCreate,setShowCreate] = useState({status:false,edit:null})
    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(5)
    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])
    const navigate = useNavigate()
    

    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})
    const RightContent = ()=>
    {
        return null
    }

    async function getRides(){

        try 
        {
            let query = `populate=true&offset=${offset}&limit=${LIMIT}`
            if(searchText) query+= `&search=${searchText}`
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/booking?${query}`)
            console.log('data',res.data)
            if(res.data) 
            {
                setRides(res.data.data)
                setCount(res.data.totalCount)
            }
        } catch (error) {
            toast('Error getting products')
        }
    }
    useEffect(()=>
    {
        getRides();
    },[searchText,offset])


    const onClick = (id)=>
    {
        navigate(`/rides/${id}`)
    }



  return (
    <div className='max-w-7xl mx-auto px-6'>
            <div className='flex justify-between items-stretch '>
        <div className='flex mb-4'>
            <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search ride'} label={'Search ride'} />
            <div className='flex w-full items-center h-full justify-center text-center border-[#d3d3d3] bg-white rounded-md shadow-sm shadow-gray-200 ml-4'>
                <div
                    className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center'
                    onClick={() => {
                    if (offset > 0) {
                        setOffset(offset - LIMIT);
                    }
                    }}
                >
                    <img src={RightIcon} className='w-[20px] h-[20px] rotate-180' alt='Previous Page' />
                </div>
                <p className='text-xs whitespace-nowrap w-[80px] justify-center'>{offset + 1}-{Math.min(offset + LIMIT, count)} of {count}</p>
                <div
                    className='px-2 py-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center'
                    onClick={() => {
                    if (offset + LIMIT < count) {
                        setOffset(offset + LIMIT);
                    }
                    }}
                >
                    <img src={RightIcon} className='w-[20px] h-[20px]' alt='Next Page' />
                </div>
                </div>
        </div>
                    {/* <div>
                    <button type='button' className='btn-md' onClick={()=>setShowCreate({status:true,edit:null})}>Add Vendor</button>
                    </div> */}
        {/* <div className='flex justify-end h-[100%] self-stretch items-center px-4 border-l border-[#e3e3e3] hover:bg-[#f6f6f6] cursor-pointer' onClick={()=>setShowFilters(true)}>
        {(filters.cityId) ? <img src={FilterActiveIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> : <img src={FilterIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> }
        </div> */}
    </div>
        <div className='flex bg-white rounded-md flex-1  w-full'>
            <table className=' w-full'>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>
                    <td><p>Booking ID / Time</p></td>
                    <td><p>User</p></td>
                    <td><p>Vehicle</p></td>
                    <td><p>Amount(In Rs.)</p></td>
                    <td><p>Status</p></td>
                    <td><p>Start/End Time</p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        rides.map((item,index)=>
                        {
                            return <tr onClick={()=>onClick(item.bookingId)}>
                                <td className='capitalize'>
                                    <div>
                                        <p className='text-xs font-medium my-0 uppercase'>{item.bookingId}</p>
                                        <p className='text-xs my-0 text-gray-400'>{getValidDateFormat(item.createdAt)}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-medium my-0'>{item.user.name ? item.user.name : 'Unavailable'}</p>
                                        <p className='text-xs my-0 text-gray-400'>{item.user.contactNumber}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-medium my-0'>{item.vehicle.vehicleName}</p>
                                        <p className='text-xs my-0 text-gray-400'>{item.vehicle.vehicleNumber ? item.vehicle.vehicleNumber : 'Not Available'}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>Rs.{item.totalAmount}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                    <Status label={item.status} type={item.status === BOOKING_INITIATED ? 'neutral' : item.status === BOOKING_CANCELLED ? 'negative' : item.status === BOOKING_ONGOING ? 'medium' : 'positive'}/>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-xs font-regular my-0'>{`${getDateFormat(item.startTime)} ${getTimeFormat(item.startTime)}`}</p>
                                        <p className='text-xs font-regular my-0'>{`${getDateFormat(item.endTime)} ${getTimeFormat(item.endTime)}`}</p>
                                    </div>
                                </td>

                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
        {/* { showCreate.status ? <ManageProduct onClose={setShowCreate} onSubmit={onSubmit} edit={showCreate.edit}/> : null} */}
    </div>
  )
}

