import { combineReducers } from 'redux';
import {persistStore,persistReducer,FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slice/authSlice'

const persistConfig = {
    key: 'root',
    version:1,
    storage,
  }
  const rootReducer = combineReducers({
    auth:authReducer
  })
  
  const persistedReducer = persistReducer(persistConfig, rootReducer)

    export const store = configureStore(
        {
            reducer:persistedReducer,
            devTools:process.env.NODE_ENV !== 'production',
            middleware:(getDefaultMiddleware) =>
            getDefaultMiddleware({
              serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
              },
            })
        }
    );

    export const persistor = persistStore(store)

