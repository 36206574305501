import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"

export default function ManageAdmin({onClose,onSubmit,updateData,edit})
{
    const [manageData,setManageData] = useState({name:'',email:'',mobile:'',permission:1,city:''})
    const [cities,setCities] = useState([])
    const [salesRep,setSalesRep] = useState([])
    const [showCrop,setShowCrop] = useState(false)
    const formName = 'editAdmin';
    const [image,setImage] = useState(null)
    const [croppedImage,setCroppedImage] = useState()

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setShowCrop(true)
        setImage(URL.createObjectURL(event.target.files[0]));
        }
        }

    // useEffect(()=>
    // {
    //     async function getCities(){
    //         let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/city`)
    //         setCities(res.data.data)
    //     }
    //     async function getTeamInfo(){
    //         let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/teams/${edit}`)
    //         console.log('team info',res)
    //         setManageData({teamName:res.data.data.teamName,teamRepId:res.data.data.teamRepId ? res.data.data.teamRepId : null,cityId:res.data.data.cityId,status:res.data.data.status,teamTarget:res.data.data.teamTarget})
    //     }
    //     getCities()
    //     getTeamInfo()
    // },[])

    // useEffect(()=>
    // {
    //     async function getRoutes(){
    //         let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/salesrepresentative?repCity=${manageData.cityId}`)
    //         setSalesRep(res.data.data)
    //     }
    //     if(manageData.cityId !== '') getRoutes()
    // },[manageData.cityId])



    return <><Popup title={edit ? 'Update Team' : 'Create Team'} submitTitle={edit ? 'Update' : 'Create'} onClose={onClose} formName={formName}>
        <form onSubmit={(e)=>onSubmit(e,{...manageData,retailerShopImage:croppedImage})} id={formName} className='grid grid-cols-2 gap-x-3 gap-y-0 w-full'>
            <div>
                <label>Admin Name*</label>
                <Input type='text' placeholder={'Enter Admin Name'} value={manageData.name} required={true} setValue={(value)=>setManageData(data=>({...data,name:value}))}/>
            </div>
            <div>
                <label>Admin Email*</label>
                <Input type='text' placeholder={'Enter Admin Email'} value={manageData.email} required={true} setValue={(value)=>setManageData(data=>({...data,email:value}))}/>
            </div>
            <div>
                <label>Admin Mobile*</label>
                <Input type='number' placeholder={'Enter Admin Mobile'} value={manageData.mobile} required={true} setValue={(value)=>setManageData(data=>({...data,mobile:value}))}/>
            </div>
            <div>
                <label>Role*</label>
                <Select placeholder={'Select Status'} options={[{name:'Manager',value:0},{name:'Employee',value:1}]}  value={parseInt(manageData.role)} setValue={(value)=>setManageData(data=>({...data,role:value}))}/>
            </div>
            <div>
                <label>City*</label>
                <Select placeholder={'Select Status'} options={[{name:'Hyderabad',value:'hyderabad'},{name:'Chennai',value:'chennai'}]}  value={manageData.city} setValue={(value)=>setManageData(data=>({...data,city:value}))}/>
            </div>
        </form>
    </Popup>
    {/* {showCrop ? <CropPopup image={image} setActive={setShowCrop} setImage={setCroppedImage}/>: null} */}
    </>
}