import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import authAxios from "../../../helpers/axios"

export default function ManageVendor({onClose,onSubmit,updateData,edit})
{
    const [manageData,setManageData] = useState({name:'',email:'',mobile:'',city:''})
    const [cities,setCities] = useState([])
    const formName = 'editVendor';

       useEffect(()=>
    {
        async function getCities(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/city`)
            setCities(res.data)
        }
        getCities()
    },[])
    




    return <><Popup title={edit ? 'Update Vendor' : 'Create Vendor'} submitTitle={edit ? 'Update' : 'Create'} onClose={onClose} formName={formName}>
        <form onSubmit={(e)=>onSubmit(e,{...manageData})} id={formName} className='grid grid-cols-2 gap-x-3 gap-y-0 w-full'>
            <div>
                <label>Vendor Name*</label>
                <Input type='text' placeholder={'Enter Vendor Name'} value={manageData.name} required={true} setValue={(value)=>setManageData(data=>({...data,name:value}))}/>
            </div>
            <div>
                <label>Vendor Email*</label>
                <Input type='text' placeholder={'Enter Vendor Email'} value={manageData.email} required={true} setValue={(value)=>setManageData(data=>({...data,email:value}))}/>
            </div>
            <div>
                <label>Vendor Mobile*</label>
                <Input type='number' placeholder={'Enter Vendor Mobile'} value={manageData.mobile} required={true} setValue={(value)=>setManageData(data=>({...data,mobile:value}))}/>
            </div>
            <div>
                <label>City*</label>
                <Select customLabel={'name'} customValue={'id'} placeholder={'Select Status'} options={cities}  value={manageData.city} setValue={(value)=>setManageData(data=>({...data,city:value}))}/>
            </div>
        </form>
    </Popup>
    {/* {showCrop ? <CropPopup image={image} setActive={setShowCrop} setImage={setCroppedImage}/>: null} */}
    </>
}