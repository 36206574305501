import React, { useEffect, useState } from 'react'
// import { useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import RightIcon from '../../images/right.svg'
import authAxios from '../../helpers/axios'
import { toast } from 'react-toastify'
import { InfoToast } from '../../helpers/toasters'
import SearchInput from '../../components/SearchInput'
import { LIMIT } from '../../helpers/constants'
import { useParams, useSearchParams } from 'react-router-dom'

export default function PaymentInfo() {

    const {id} = useParams()
    const [rideInfo,setRideInfo] = useState([])
    const [showCreate,setShowCreate] = useState({status:false,edit:null})


    async function getPaymentInfo(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/booking/${id}?populate=true`)
            console.log('data',res.data)
            if(res.data) setRideInfo(res.data)
        } catch (error) {
            toast('Error getting products')
        }
    }
    useEffect(()=>
    {
        getPaymentInfo();
    },[])




  return (
    <div className='max-w-7xl mx-auto px-6'>
            <div className='flex justify-between items-stretch '>
                    {/* <div>
                    <button type='button' className='btn-md' onClick={()=>setShowCreate({status:true,edit:null})}>Add Vendor</button>
                    </div> */}
    </div>
        <div className='bg-white rounded-md shadow-sm px-6 py-4'>
        </div>
        {/* { showCreate.status ? <ManageProduct onClose={setShowCreate} onSubmit={onSubmit} edit={showCreate.edit}/> : null} */}
    </div>
  )
}

