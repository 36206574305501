import React, { useEffect, useState } from 'react'
import authAxios from '../../helpers/axios'
import { Link, Outlet, useNavigate, useNavigation, useParams } from 'react-router-dom'
import Loader from '../../components/Loader'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/react-splide/css';
import { toast } from 'react-toastify'
import Popup from '../../components/Popup'
import Select from '../../components/Select'
import Input from '../../components/Input'
import SingleImageHolder from '../../components/SingleImageHolder'
import CarImageInfoUploader from '../../components/CarImageInfoUploader'
import { FEATURES } from '../../helpers/constants'
import { convertStringToArray } from '../../helpers/utils'

export default function VehicleInformation() {

    const {id} = useParams()
    const [data,setData] = useState({})
    const [loading,setLoading] = useState(true)
    const [showManage,setShowManage] = useState(false)
    const [showRemoveImage,setShowRemoveImage] = useState(false)
    const [showSetCover,setShowSetCover] = useState(false)
    const [showDelete,setShowDelete] = useState(false)
    const [showUpdatePlan,setShowUpdatePlan] = useState(false)
    // // const [showEdit]
    async function getVehicleInformation(){
        let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/vehicle/${id}`)
        setData(res.data)
        setLoading(false)
    }
    async function setCoverImage(imageId){
        let res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/vehicle/update-cover/${id}/${imageId}`)
        // setData(res.data)
        getVehicleInformation(false)
    }

    useEffect(()=>
    {
        getVehicleInformation();
    },[])


  return (
    <>
    { !loading ? <div>
        <div className='bg-white rounded-md shadow-sm px-6 py-2'>
            <div className='flex justify-between py-3 border-b border-b-slate-200'>
            <p className='text-sm font-medium text-[#959595]'>Vehicle Basic Information</p>
            <div className='flex'>
                <button onClick={()=>setShowManage(true)} className='btn-xs mr-4'>Update</button>
                <button onClick={()=>setShowDelete(true)} className='btn-xs'>Delete</button>
            </div>
            </div>
            <div className='grid grid-cols-4 gap-x-3 mt-8 gap-y-4 py-4'>
                <div>
                    <label>Vehicle Number</label>
                    <p className='text-sm font-medium'>{data.vehicleNumber}</p>
                </div>
                <div>
                    <label>Vehicle Model</label>
                    <p className='text-sm font-medium'>{data.vehicleName}</p>
                </div>
                <div>
                    <label>Vehicle Brand</label>
                    <p className='text-sm font-medium'>{data.brand.name}</p>
                </div>
                <div>
                    <label>Model Year</label>
                    <p className='text-sm font-medium'>{data.vehicleYear}</p>
                </div>
                <div>
                    <label>Owner Type</label>
                    <p className='text-sm font-medium'>{data.ownerType === 1 ? 'Vendor' : 'Company Owned'}</p>
                </div>
                <div>
                    <label>Vehicle Type</label>
                    <p className='text-sm font-medium caps'>{data.vehicleType}</p>
                </div>
                <div>
                    <label>Vehicle Seats</label>
                    <p className='text-sm font-medium'>{data.vehicleSeats} Seats</p>
                </div>
                <div>
                    <label>Owner Fuel Type</label>
                    <p className='text-sm font-medium'>{data.vehicleFuelType}</p>
                </div>
                <div>
                    <label>Features</label>
                    <p className='text-sm font-medium capitalize'>{data.features}</p>
                </div>
                <div>
                    <label>Status</label>
                    <p className='text-sm font-medium capitalize'>{data.active ? 'Active' : 'Inactive'}</p>
                </div>
            </div>
        </div>
        <div className='bg-white rounded-md shadow-sm px-6 py-4 mt-4'>
            <div className='flex justify-between items-center mb-6'>
                <p className='text-md font-semibold mb-4 tracking-tight'>Images</p>
                <CarImageInfoUploader type={'listing'} getCarInfo={getVehicleInformation} vehicleId={id}/>
            </div>
            <Splide options={ {autoWidth:false,gap:'24px',arrows:false,perPage:3} } aria-label="React Splide Example">
                {
                  data.images.map((item,index)=>
                  {
                    return <SplideSlide key={index}>
                        <div className='relative'>
                            {item.isCover ? <div className='absolute bg-[#151515] rounded-md px-2 m-2 py-1'><p className='text-white text-xs font-medium'>Cover</p></div> : null}
                            {!item.isCover ? <div onClick={()=>setCoverImage(item.id)} className='absolute bg-[#151515] hover:bg-[#454545] cursor-pointer rounded-md px-2 m-2 py-1'><p className='text-white text-xs font-medium'>Set As Cover</p></div> : null}
                            {!item.isCover ? <div onClick={()=>setShowRemoveImage(item.id)} className='absolute right-0 bg-[red] hover:bg-[#bf3939] cursor-pointer rounded-md px-2 m-2 py-1'><p className='text-white text-xs font-medium'>Remove</p></div> : null}
                        <img src={item.url} className='rounded-md'/>
                        </div>
                        </SplideSlide>
                  })
                }
              </Splide>
        </div>

        <div className='bg-white rounded-md shadow-sm px-6 py-4 mt-4'>
            <div className='pb-3 border-b border-b-slate-200 flex w-full justify-between'>
            <p className='text-sm font-medium text-[#959595] '>Vehicle Plan Information</p>
            <button className='btn-xs' onClick={()=>setShowUpdatePlan(true)}>Update Plan</button>
            </div>
            <div className='grid grid-cols-4 gap-x-3 mt-8 gap-y-3'>
                <div>
                    <label>Per Hour Fee</label>
                    <p className='text-sm font-medium'>{data.vehiclePlan[0].perHourFee}</p>
                </div>
                <div>
                    <label>Distance Alloted</label>
                    <p className='text-sm font-medium'>{data.vehiclePlan[0].kmAlloted}</p>
                </div>
                <div>
                    <label>Extra Km Fee</label>
                    <p className='text-sm font-medium'>{data.vehiclePlan[0].extraKmFee}</p>
                </div>
            </div>
        </div>
    </div> : <Loader/>}
    {showManage ? <UpdateVehicle onSubmit={getVehicleInformation} id={id} onClose={()=>setShowManage(false)}/> : null}
    {showRemoveImage ? <RemoveImage onSubmit={getVehicleInformation} id={showRemoveImage} onClose={()=>setShowRemoveImage(false)}/> : null}
    {/* {showRemoveImage ? <RemoveImage onSubmit={getVehicleInformation} id={showRemoveImage} onClose={()=>setShowRemoveImage(false)}/> : null} */}
    {showUpdatePlan ? <UpdatePlan onSubmit={getVehicleInformation} id={id} onClose={()=>setShowUpdatePlan(false)}/> : null}
    {showDelete ? <DeleteVehicle onSubmit={getVehicleInformation} id={id} onClose={()=>setShowDelete(false)}/> : null}
    
    </>
  )
}




const RemoveImage = ({onClose,id,onSubmit})=>
{
    const [submitting,setSubmitting] = useState(false)


    async function removeVehicleImage(e){

        try 
        {
            e.preventDefault()
            setSubmitting(true)
            let res = await authAxios.delete(`${process.env.REACT_APP_BASE_URL}/vehicle/remove-photo/${id}`)
            await onSubmit()
            onClose()
            setSubmitting(false)
    } catch (error) {
        console.log(error)
        setSubmitting(false)
            toast('Error getting Users')
        }
    }


    return <Popup title={'Remove Image'} submitting={submitting} onSubmittingTitle='Removing Image...' onClose={onClose} formName={'removeVehicleImage'} submitTitle={'Remove Image'}>
        <form onSubmit={removeVehicleImage} id="removeVehicleImage" name="removeVehicleImage">
        <div className="py-6">
            <div>
                <p className='text-base'>Do you want to remove the vehile Image?</p>
            </div>
            </div>
        </form>
    </Popup>
}

const UpdateVehicle = ({onClose,id,onSubmit})=>
{
    const [loading,setLoading] = useState(true)
    const [submitting,setSubmitting] = useState(false)
    const [info,setInfo] = useState()
    const [brands,setBrands] = useState([])
    const [vendors,setVendors] = useState([])
    const [cities,setCities] = useState([])
    const [types,setTypes] = useState([{id:'hatchback',value:'Hatchback'},{id:'sedan',value:'Sedan'},{id:'csuv',value:'Compact SUV'},{id:'suv',value:'SUV'}])
    const [pickups,setPickups] = useState([])
    const [manageData,setManageData] = useState({vehicleName:'',vehicleYear:'',vehicleBrand:'',vehicleType:1,ownerType:'',ownerId:'',city:'',vehicleFuelType:'',plan:{perHourFee:'',kmAlloted:'',extraKmFee:'',description:'',vehicleNumber:''},features:[]})

    
    useEffect(()=>
    {
        async function getCities(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/city`)
            setCities(res.data)
        }
        async function getBrands(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/brand`)
            setBrands(res.data)
        }
        async function getVendors(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/vendor`)
            setVendors(res.data)
        }
        async function getPickups(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/pickup-point`)
            setPickups(res.data.rows)
        }
        getCities()
        getBrands()
        getVendors()
        getPickups()
        getVehicleInformation()
    },[])

    async function getVehicleInformation(){
        let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/vehicle/${id}`)
        setManageData({...res.data,features:convertStringToArray(res.data.features)})
        setLoading(false)
    }


    async function updateVehiclePlan(e){

        try 
        {
            e.preventDefault()
            setSubmitting(true)
            let res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/vehicle/${id}`,{...manageData,vehiclePlan:null})
            await onSubmit()
            onClose()
            setSubmitting(false)
    } catch (error) {
        console.log(error)
        setSubmitting(false)
            toast('Error getting Users')
        }
    }

    const handleCheckboxChange = (value) => {
        setManageData((prevState) => {
          const features = prevState.features.includes(value)
            ? prevState.features.filter((item) => item !== value)
            : [...prevState.features, value];
    
          return { ...prevState, features };
        });
      };


    return <Popup title={'Update Vehicle'} onClose={onClose} formName={'updateVehiclePlan'} submitTitle={'Update'}>
        <form onSubmit={updateVehiclePlan} id="updateVehiclePlan" name="updateVehiclePlan">
        <div className="border-b border-b-slate-200 grid grid-cols-6 gap-x-3 gap-y-0 py-6">
            <div className="col-span-2">
                <label>Vehicle Name*</label>
                <Input type='text' placeholder={'Enter Vehicle Name'} value={manageData.vehicleName} required={true} setValue={(value)=>setManageData(data=>({...data,vehicleName:value}))}/>
            </div>
            {/* <div className="col-span-2">
                <label>City*</label>
                <Select placeholder={'Select City'} customLabel={'name'} customValue={'id'} options={cities}  value={manageData.pickupPoint?.cityId} setValue={(value)=>setManageData(data=>({...data,city:value}))}/>
            </div> */}
            <div className="col-span-2">
                <label>Fuel Type*</label>
                <Select placeholder={'Select Fuel Type'} customLabel={'name'} customValue={'id'} options={[{name:'Petrol',value:0},{name:'Diesel',value:1},{name:'Electric',value:2}]}  value={manageData.vehicleFuelType} setValue={(value)=>setManageData(data=>({...data,vehicleFuelType:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Vehicle Model Year*</label>
                <Input number={true} type='text' placeholder={'Enter Model Year'} value={manageData.vehicleYear} required={true} setValue={(value)=>setManageData(data=>({...data,vehicleYear:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Vehicle Brand*</label>
                <Select placeholder={'Select Brand'} customLabel={'name'} customValue={'id'} options={brands}  value={manageData.vehicleBrand} setValue={(value)=>setManageData(data=>({...data,vehicleBrand:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Vehicle Type*</label>
                <Select placeholder={'Select Vehicle Type'} customLabel={'value'} customValue={'id'} options={types}  value={manageData.vehicleType} setValue={(value)=>setManageData(data=>({...data,vehicleType:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Vehicle CC</label>
                <Input number={false} type='text' placeholder={'Enter Vehicle CC'} value={manageData.vehicleCc} required={true} setValue={(value)=>setManageData(data=>({...data,vehicleCc:value}))}/>
            </div>
            <div className="col-span-3">
                <label>Owner Type*</label>
                <Select placeholder={'Select Owner Type'} options={[{name:'Owner',value:0},{name:'Vendor',value:1}]}  value={manageData.ownerType} setValue={(value)=>setManageData(data=>({...data,ownerType:value}))}/>
            </div>
            {manageData.ownerType === 1 ? <div className="col-span-3">
                <label>Vendor*</label>
                <Select viewMode={manageData.ownerType === 1 ? true : false} customLabel={'name'} customValue={'id'} placeholder={'Select Vendor'} options={vendors}  value={manageData.ownerId} setValue={(value)=>setManageData(data=>({...data,ownerId:value}))}/>
            </div> : <div className="col-span-3"></div>}
            <div className="col-span-2">
                <label>Vehicle Number</label>
                <Input number={false} type='text' placeholder={'Eg., TG-10BS-1213'} value={manageData.vehicleNumber} required={true} setValue={(value)=>setManageData(data=>({...data,vehicleNumber:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Vehicle Pickup Point</label>
                <Select placeholder={'Select Pickup Point'} customLabel={'name'} customValue={'id'} options={pickups}  value={manageData.pickupId} setValue={(value)=>setManageData(data=>({...data,pickupId:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Vehicle Transmission</label>
                <Select placeholder={'Select Transmission Type'} options={[{name:'Manual',value:'manual'},{name:'Automatic',value:'automatic'}]}  value={manageData.vehicleTransmission} setValue={(value)=>setManageData(data=>({...data,vehicleTransmission:value}))}/>
            </div>
            <div className='col-span-4'>
            <p className="text-sm tracking-tight font-medium mt-4">Features</p>
            <div className="grid grid-cols-6 gap-x-3 gap-y-0 w-full py-6">
                <div className="col-span-2">
                    {
                        FEATURES.map((item,index)=>
                        {
                            return <div className="flex"><input key={index}
                            type="checkbox" className="mr-1"
                            id={item.value}
                            checked={manageData.features.includes(item.value)}
                            onChange={() => handleCheckboxChange(item.value)}
                          />
                          <label htmlFor={item.value} className="ml-2 text-black text-sm">{item.label}</label>
                          </div>
                        })
                    }
                </div>
            </div>
            </div>
             <div className="col-span-3">
                <label>Status*</label>
                <Select placeholder={'Select Status'} options={[{name:'Active',value:true},{name:'Inactive',value:false}]}  value={manageData.active} setValue={(value)=>setManageData(data=>({...data,active:value}))}/>
            </div>
            {/*<div className="col-span-3">
                <label>Coming Soon*</label>
                <Select placeholder={'Select Coming Soon Status'} options={[{name:'Yes',value:true},{name:'No',value:false}]}  value={manageData.ownerType} setValue={(value)=>setManageData(data=>({...data,availableSoon:value}))}/>
            </div>*/}
            </div>
        </form>
    </Popup>
}

const UpdatePlan = ({onClose,id,onSubmit})=>
{
    const [loading,setLoading] = useState(true)
    const [submitting,setSubmitting] = useState(false)
    const [manageData,setManageData] = useState({perHourFee:'',kmAlloted:'',extraKmFee:''})

    
    useEffect(()=>
    {
        getVehicleInformation()
    },[])

    async function getVehicleInformation(){
        let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/vehicle/${id}`)
        setManageData(res.data.vehiclePlan[0])
        setLoading(false)
    }


    async function updateVehiclePlan(e){

        try 
        {
            e.preventDefault()
            setSubmitting(true)
            let res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/vehicle/${id}`,{vehiclePlan:manageData})
            await onSubmit()
            onClose()
            setSubmitting(false)
    } catch (error) {
        console.log(error)
        setSubmitting(false)
            toast('Error getting Users')
        }
    }


    return <Popup title={'Update Vehicle'} onClose={onClose} formName={'updateVehicle'} submitTitle={'Update'}>
        <form onSubmit={updateVehiclePlan} id="updateVehicle" name="updateVehicle">
        <div className="border-b border-b-slate-200 grid grid-cols-6 gap-x-3 gap-y-0 py-6">
            <div className="col-span-2">
                <label>Per Hour Fee*</label>
                <Input number={true} type='text' placeholder={'Enter Fee'} value={manageData.perHourFee} required={true} setValue={(value)=>setManageData(data=>({...data,perHourFee:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Distance Alloted (Per Hour)*</label>
                <Input number={true} type='text' placeholder={'Enter Kms'} value={manageData.kmAlloted} required={true} setValue={(value)=>setManageData(data=>({...data,kmAlloted:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Extra Km Fee*</label>
                <Input number={true} type='text' placeholder={'Enter Fee'} value={manageData.extraKmFee} required={true} setValue={(value)=>setManageData(data=>({...data,extraKmFee:value}))}/>
            </div>
            </div>
        </form>
    </Popup>
}


const DeleteVehicle = ({onClose,id,onSubmit})=>
{
    const [loading,setLoading] = useState(true)
    const [submitting,setSubmitting] = useState(false)
    const navigation = useNavigate()

    async function deleteVehicle(e){

        try 
        {
            e.preventDefault()
            setSubmitting(true)
            let res = await authAxios.delete(`${process.env.REACT_APP_BASE_URL}/vehicle/${id}`)
            await onSubmit()
            navigation('/vehicles/')
            setSubmitting(false)
    } catch (error) {
        console.log(error)
        setSubmitting(false)
            toast('Error getting Users')
        }
    }


    return <Popup title={'Delete Vehicle'} onClose={onClose} formName={'deleteVehicle'} submitTitle={'Delete'}>
        <form onSubmit={deleteVehicle} id="deleteVehicle" name="deleteVehicle">
            <div className="block w-full">
                    <p className="text-sm ">Are you sure to delete the vehicle?</p>
            </div>
        </form>
    </Popup>
}