import React, { useState,useEffect } from 'react'
import { toast } from 'react-toastify'
import Popup from '../../../components/Popup'
import Input from '../../../components/Input'
import EditImage from '../../../images/edit.svg'
import authAxios from '../../../helpers/axios'
import { InfoToast } from '../../../helpers/toasters'

export default function Memebership() {
    const [membership ,setMembership] = useState([])
    const [showManage,setShowManage] = useState({status:false,edit:null})

    async function getMembership(){
        let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/membership-type`)
        setMembership(res.data)
    }

    useEffect(()=>
    {
        getMembership();
    },[])

    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            let res;
            if(showManage.edit)
            {
                res = await authAxios.put(`/membership-type/update`,{...data})  
            }
            else
            {
                res = await authAxios.put(`/membership-type/update`,{...data}) 
                
            }
            if(res.data)
            {
                if(showManage.edit)
                {
                    setMembership(brands=>{
                        let prev = [...brands]
                        let itemIndex = prev.findIndex(item=>item.id === showManage.edit)
                        console.log('itemIndex',itemIndex,'res',res.data)
                        prev[itemIndex] = {...prev[itemIndex],membershipName:res.data.membershipName}
                        return prev;
                    })
                }
                else setMembership(brands=>([...brands,{...res.data}]))
                InfoToast(showManage.edit ? 'Membership Updated' : 'Membership Created')
                await getMembership()
                setShowManage({status:false,edit:null})
            }
            else InfoToast('error creating Membership')
        } catch (error) {
            console.log(error)
            toast.error(error)
        }
    }

  return (
    <div className='w-full'>
        <div className='w-full'>
        {!membership?.id ?<div className='flex justify-end items-center'>
            <button className='btn-md' onClick={()=>setShowManage({type:'brand',status:true})}>Add Membership Type</button>
        </div> : null}
        <div className="flex w-full ">
            {membership?.id ? <MembershipItem setShow={setShowManage} item={membership}/> : <div><p>Membership Not Created</p></div>}
        </div>
    </div>
    {showManage.status === true ? <ManageMembership setShow={setShowManage} onSubmit={onSubmit} edit={showManage.edit}/> : null}
    </div>
  )
}

const MembershipItem = ({item,setShow})=>
{
     return         <div className='bg-white rounded-md shadow-sm px-6 py-4 mt-4 w-full'>
     <div className='pb-3 border-b border-b-slate-200 flex w-full justify-between'>
     <p className='text-sm font-medium text-[#959595] '>{item.membershipName}</p>
     <div><button className='btn-xs' onClick={()=>setShow({status:true,edit:true})}>Update Info</button></div>
     </div>
 <div className='grid grid-cols-4 gap-9 pt-6'>
     <div>
         <label>Name</label>
         <p className='text-sm'>{`${item.membershipName}`}</p>
     </div>
     <div>
         <label>Membership Amount</label>
         <p className='text-sm'>{item.membershipAmount ? `Rs.${item.membershipAmount}` : 'Not Available'}</p>
     </div>
     <div>
         <label>Membership Offer Amount</label>
         <p className='text-sm'>{item.membershipOfferAmount ? `Rs.${item.membershipOfferAmount}` : 'No Offer'}</p>
     </div>
     <div>
         <label>Vehicle Offer Price</label>
         <p className='text-sm'>{item.membershipRideOffer ? `Rs.${item.membershipRideOffer}` : 'Not Applicable'}</p>
     </div>
     <div>
         <label>Vehicle Offer Cap Limit</label>
         <p className='text-sm'>{item.membershipOfferMax ? `Rs.${item.membershipOfferMax}` : 'Not Applicable'}</p>
     </div>
     </div>
 </div>
}

const ManageMembership = ({setShow,onSubmit,edit=false})=>
{
    const [membership,setMembership] = useState({id:null,membershipName:'',membershipAmount:'',membershipOfferAmount:'',membershipRideOffer:'',membershipOfferMax:''})
    const [loading,setLoading] = useState(edit ? true : false)
    useEffect(()=>
    {
        async function getMembershipInfo(){
            if(edit)
            {
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/membership-type/`)
                setMembership({id:res.data?.id,membershipName:res.data?.membershipName,membershipAmount:res.data?.membershipAmount,membershipOfferAmount:res.data?.membershipOfferAmount,membershipRideOffer:res.data?.membershipRideOffer,membershipOfferMax:res.data?.membershipOfferMax})
                setLoading(false)
            }
        }
        getMembershipInfo()
    },[])
    return loading ? 'loading' :<Popup onClose={()=>setShow({status:false})}  title={edit ?  'Edit Membership' : 'Add Membership'} submitTitle={edit ? 'Update' : 'Add'} formName={'createMembership'}>
        <form className='w-full grid grid-cols-2 gap-x-2' onSubmit={(e)=>onSubmit(e,edit ? membership : membership)} id="createMembership">
            <div className='col-span-1'>
                <label>Membership Name</label>
                <Input placeholder={'Enter Membership Name'} value={membership.membershipName} setValue={(value)=>setMembership(membership=>({...membership,membershipName:value}))} required={true}/>
            </div>
            <div className='col-span-1'>
                <label>Membersip Amount</label>
                <Input placeholder={'Enter Membership Amount'} value={membership.membershipAmount} setValue={(value)=>setMembership(membership=>({...membership,membershipAmount:value}))} required={true} type='number'/>
            </div>
            <div className='col-span-1'>
                <label>Membership Offer Amount</label>
                <Input placeholder={'Enter Offer Amount'} value={membership.membershipOfferAmount} setValue={(value)=>setMembership(membership=>({...membership,membershipOfferAmount:value}))} required={false} type='number'/>
            </div>
            <div className='col-span-1'>
                <label>Vehicle Offer Percentage</label>
                <Input placeholder={'Enter Offer Percentage'} value={membership.membershipRideOffer} setValue={(value)=>setMembership(membership=>({...membership,membershipRideOffer:value}))} required={true} type='number'/>
            </div>
            <div className='col-span-1'>
                <label>Vehicle Offer Max Cap</label>
                <Input placeholder={'Enter Offer Max Cap'} value={membership.membershipOfferMax} setValue={(value)=>setMembership(membership=>({...membership,membershipOfferMax:value}))} required={false} type='number'/>
            </div>
        </form>
    </Popup>
}