import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
// import SearchInput from '../../components/SearchInput'
import authAxios from '../../helpers/axios'
import { toast } from 'react-toastify'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { InfoToast } from '../../helpers/toasters'
import ManageVehicle from './components/ManageVehicle'
import SearchInput from '../../components/SearchInput'
import { LIMIT } from '../../helpers/constants'
import RightIcon from '../../images/right.svg'

export default function Vehicles() {

    const {showAdd} = useSearchParams()
    const [searchText,setSearchText] = useState('')
    const [vehicles,setVehicles] = useState([])
    const [showCreate,setShowCreate] = useState({status:showAdd === 1 ? true : false,edit:null})
    const [sort,setSort] = useState('-createdOn')
    const navigate = useNavigate()
    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(5)

    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])
    

    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})


    const handleModal = (value) => {
    navigate({
        pathname: window.location.pathname,
        search: `?showManage=${value}`,
    });

    // history.pushState(null, null, window.location.href);
    };

    const RightContent = ()=>
    {
        return <div>
          <button type='button' className='btn-md' onClick={()=>handleModal(1)}>Add Vehicle</button>
        </div>
    }

    async function getVehicles(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/admin/vehicle?populate=true`)
            if(res.data) setVehicles(res.data)
        } catch (error) {
            toast('Error getting vehicles')
        }
    }
    useEffect(()=>
    {
        getVehicles();
    },[])

    const onSubmit = async(e,data,images)=>
    {
        try 
        {
            let imageList = []
            e.preventDefault()
            images.map((item)=>
            {
                imageList.push({src:item.src,isCover:item.isCover ? item.isCover : false})
            })
            console.log('images',images)
            let imageRes
            let res;
            if(showCreate.edit)
            {
                let updateData = {...data,images:imageList}
                res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/vehicle/${showCreate.edit}`,updateData) 
                InfoToast('Vehicle Updated')
                
            }
            else
            {
                console.log('images',imageList)
                res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/vehicle`,{...data,images:imageList})
                InfoToast('Vehicle Created')
            }
            if(res.data)
            {
                await getVehicles()
                setShowCreate({status:false,edit:null})
            }
            else toast('error updating retailer')
        } catch (error) {
            // console.log(error.response.data.error[0])
            toast.error(error.response.data.error[Object.keys(error.response.data.error)[0]])
        }
    }


  return (
    <div className='max-w-7xl mx-auto px-6'>
        <div className='flex justify-between items-stretch '>
        <div className='flex mb-4'>
            <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search vendor'} label={'Search vendor'} />
            <div className='flex w-full items-center h-full justify-center text-center border-[#d3d3d3] bg-white rounded-md shadow-sm shadow-gray-200 ml-4'>
                <div
                    className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center'
                    onClick={() => {
                    if (offset > 0) {
                        setOffset(offset - LIMIT);
                    }
                    }}
                >
                    <img src={RightIcon} className='w-[20px] h-[20px] rotate-180' alt='Previous Page' />
                </div>
                <p className='text-xs whitespace-nowrap w-[80px] justify-center'>{offset + 1}-{Math.min(offset + LIMIT, count)} of {count}</p>
                <div
                    className='px-2 py-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center'
                    onClick={() => {
                    if (offset + LIMIT < count) {
                        setOffset(offset + LIMIT);
                    }
                    }}
                >
                    <img src={RightIcon} className='w-[20px] h-[20px]' alt='Next Page' />
                </div>
                </div>
        </div>
                    <div>
                    <button type='button' className='btn-md' onClick={()=>setShowCreate({status:true,edit:null})}>Add Vehicle</button>
                    </div>
        {/* <div className='flex justify-end h-[100%] self-stretch items-center px-4 border-l border-[#e3e3e3] hover:bg-[#f6f6f6] cursor-pointer' onClick={()=>setShowFilters(true)}>
        {(filters.cityId) ? <img src={FilterActiveIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> : <img src={FilterIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> }
        </div> */}
    </div>
        <div className='flex-auto rounded-md flex justify-between items-stretch border-0 border-[#fff] '>
        </div>
        <div className='grid grid-cols-4 gap-4 flex-1 bg-[#f3f3f3] w-full mx-auto ' >
            {
                vehicles.map((item,index)=>
                {
                    return <CarItem data={item} index={index} key={index}/>
                })
            }
        </div>
        { showCreate.status ? <ManageVehicle onClose={setShowCreate} onSubmit={onSubmit} edit={showCreate.edit}/> : null}
    </div>
  )
}



const CarItem = ({data,index})=>
{
  return <div className={`col-span-1 shadow-md shadow-gray-200 my-4 translate-y-0 hover:translate-y-1 hover:shadow-none transition-all rounded-md overflow-hidden z-0`}>
    <Link to={`/vehicles/${data.vehicleId}`}>
    <div className={`w-full h-[140px] relative`}>
        <img src={data.images.length > 0 ? data.images[0].url : ''}  className='w-full h-full' />
    </div>
    <div className='bg-white px-4 py-4'>
          <div className='pb-3'>
            <p className='text-sm font-medium capitalize'>{data.brand.name} {data.vehicleName}</p>
            <p className='text-xs text-[#959595] capitalize mt'>{data.vehicleFuelType} &middot; {data.vehicleSeats} Seater &middot; {data.vehicleYear}</p>
          </div>
          <div className='pt-3 border-t border-t-gray-200'>
            <p className='text-lg font-bold'><span className='text-sm font-medium'>Rs.</span>{data.vehiclePlan[0].perHourFee}<span className='text-sm font-medium'>/hr</span></p>
            <p className='text-xs text-[#959595] capitalize'>Available from 24 Oct 12:00 Pm</p>
          </div>
    </div>
    </Link>
  </div>
}