import React, { useEffect, useState } from 'react'
import StatusOrders from './components/StatusOrders'
import { RouteOrders } from './components/RouteOrders'
import { CityOrders } from './components/CityOrders'
import { PriceData } from './components/PriceData'
import { ProductOrders } from './components/ProductOrders'
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import axios from 'axios'
import Input from '../../components/Input'
import { getDateFormat, getValidDateFormat } from '../../helpers/utils'
import Loader from '../../components/Loader'

export default function Dashboard() {
  
  const [type,setType] = useState('All')
  const [counts,setCounts] = useState('')
  const [analytics,setAnalytics] = useState(null)
  const [startDate,setStartDate] = useState('')
  const [endDate,setEndDate] = useState('')
  const [loading,setLoading] = useState(true)


  // const onPress = (value) => {
  //   const today = new Date();
  //   setType(value)
  //   if (value === '1D') {
  //     // Set startDate and endDate to today
  //     setStartDate(today);
  //     setEndDate(today);
  //   } else if (value === '1M') {
  //     // Calculate startDate as 1 month before today
  //     const oneMonthAgo = new Date(today);
  //     oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

  //     setStartDate(oneMonthAgo);
  //     setEndDate(today);
  //   } else if (value === '1W') {
  //     // Calculate startDate as 1 week before today
  //     const oneWeekAgo = new Date(today);
  //     oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

  //     setStartDate(oneWeekAgo);
  //     setEndDate(today);
  //   }
  // };

  const onPress = (value) => {
    const today = new Date();
    setType(value);
  
    if (value === '1D') {
      // Set startDate and endDate to today
      setStartDate(today);
      setEndDate(today);
    } else if (value === '1W') {
      // Calculate startDate as 1 week before today
      const oneWeekAgo = new Date(today);
      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  
      setStartDate(oneWeekAgo);
      setEndDate(today);
    } else if (value === '1M') {
      // Calculate startDate as 1 month before today
      const oneMonthAgo = new Date(today);
      oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
  
      setStartDate(oneMonthAgo);
      setEndDate(today);
    } else if (value === '3M') {
      // Calculate startDate as 3 months before today
      const threeMonthsAgo = new Date(today);
      threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
  
      setStartDate(threeMonthsAgo);
      setEndDate(today);
    } else if (value === '6M') {
      // Calculate startDate as 6 months before today
      const sixMonthsAgo = new Date(today);
      sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
  
      setStartDate(sixMonthsAgo);
      setEndDate(today);
    } else if (value === '1Y') {
      // Calculate startDate as 1 year before today
      const oneYearAgo = new Date(today);
      oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  
      setStartDate(oneYearAgo);
      setEndDate(today);
    } else if (value === 'All') {
      // Set both startDate and endDate to empty strings
      setStartDate('');
      setEndDate('');
    }
  };
  

  useEffect(()=>
  {
    async function getOrderAnalytics()
    {
      let query = ''
    //   if(startDate) query +=`startDate=${getValidDateFormat(startDate)}`
    //   if(endDate) query +=`&endDate=${getValidDateFormat(endDate)}`;
    //     let res = await axios.get(`${process.env.REACT_APP_BASE_URL}/analytics/admin?${query}`)
    //     setAnalytics(res.data.data)
        setLoading(false)
    }
    getOrderAnalytics()
  },[startDate,endDate])

  useEffect(()=>
  {
    async function getCounts()
    {
        // let res = await axios.get(`${process.env.REACT_APP_BASE_URL}/analytics/count`)
        let res;
        setCounts(res?.data.data)
    }
    getCounts()
  },[])


  const tabItems = [{label:'This Month',value:'this_month'},{label:'Last Month',value:'last_month'}]
  const [activeTab,setActiveTab] = useState('this_month')
  return (
    !loading ? <div className='px-6 py-6 overflow-scroll'>
        <div className="grid grid-cols-7 flex-wrap gap-y-3 bg-white border border-gray-200 overflow-hidden rounded-md">
          <div className='border-r border-gray-200 py-6 px-6'>
            <p className='text-2xl font-bold mb-0'>{counts?.products ? counts.products : '-'}</p>
            <p className='text-sm font-medium text-[#959595] mt-0'>Total Rides</p>
          </div>
          <div className='border-r border-gray-200 py-6 px-6'>
            <p className='text-2xl font-bold mb-0'>{counts?.salesReps ? counts.salesReps : '-'}</p>
            <p className='text-sm font-medium text-[#959595] mt-0'>Vehicles</p>
          </div>
          <div className='border-r border-gray-200 py-6 px-6'>
            <p className='text-2xl font-bold mb-0'>{counts?.products ? counts.products : '-'}</p>
            <p className='text-sm font-medium text-[#959595] mt-0'>Users</p>
          </div>
          <div className='border-r border-gray-200 py-6 px-6'>
            <p className='text-2xl font-bold mb-0'>{counts?.retailers ? counts.retailers : '-'}</p>
            <p className='text-sm font-medium text-[#959595] mt-0'>Vendors</p>
          </div>
          <div className='border-r border-gray-200 py-6 px-6'>
            <p className='text-2xl font-bold mb-0'>{counts?.distributors ? counts.distributors : '-'}</p>
            <p className='text-sm font-medium text-[#959595] mt-0'>Pickup Points</p>
          </div>
          <div className='border-r border-gray-200 py-6 px-6'>
            <p className='text-2xl font-bold mb-0'>{counts?.products ? counts.products : '-'}</p>
            <p className='text-sm font-medium text-[#959595] mt-0'>Cities</p>
          </div>
          <div className='border-r border-gray-200 py-6 px-6'>
            <p className='text-2xl font-bold mb-0'>{counts?.routes ? counts.routes : '-'}</p>
            <p className='text-sm font-medium text-[#959595] mt-0'>Live Rides</p>
          </div>
        </div>
        <div className='my-4 flex justify-between items-center'>
        <p className='text-sm font-medium tracking-tight  text-gray-700'>Total Rides- {analytics?.orderStatuses?.totalOrders ? analytics?.orderStatuses?.totalOrders : 0}</p>
        <div className=' flex items-center'>
            <div className='relative flex mr-4 bg-white border border-gray-200 rounded-md overflow-hidden'>
              <div className={`px-3 py-2 periodTabItem border-r border-gray-200 transition-colors cursor-pointer text-[#757575] hover:bg-[#ecc032] hover:text-white ${type === '1D' ? 'bg-[#ecc032] text-white' : ''}`} onClick={()=>onPress('1D')}>
                <p className='text-xs font-medium capitalize tracking-tight'>1D</p>
              </div>
              <div className={`px-3 py-2 periodTabItem border-r border-gray-200 transition-colors cursor-pointer text-[#757575] hover:bg-[#ecc032] hover:text-white ${type === '1W' ? 'bg-[#ecc032] text-white' : ''}`} onClick={()=>onPress('1W')}>
                <p className='text-xs font-medium capitalize tracking-tight'>1W</p>
              </div>
              <div className={`px-3 py-2 periodTabItem border-r border-gray-200 transition-colors cursor-pointer text-[#757575] hover:bg-[#ecc032] hover:text-white ${type === '1M' ? 'bg-[#ecc032] text-white' : ''}`} onClick={()=>onPress('1M')}>
                <p className='text-xs font-medium capitalize  tracking-tight'>1M</p>
              </div>
              <div className={`px-3 py-2 periodTabItem border-r border-gray-200 transition-colors cursor-pointer text-[#757575] hover:bg-[#ecc032] hover:text-white ${type === '3M' ? 'bg-[#ecc032] text-white' : ''}`} onClick={()=>onPress('3M')}>
                <p className='text-xs font-medium capitalize  tracking-tight'>3M</p>
              </div>
              <div className={`px-3 py-2 periodTabItem border-r border-gray-200 transition-colors cursor-pointer text-[#757575] hover:bg-[#ecc032] hover:text-white ${type === '6M' ? 'bg-[#ecc032] text-white' : ''}`} onClick={()=>onPress('6M')}>
                <p className='text-xs font-medium capitalize  tracking-tight'>6M</p>
              </div>
              <div className={`px-3 py-2 periodTabItem border-r border-gray-200 transition-colors cursor-pointer text-[#757575] hover:bg-[#ecc032] hover:text-white ${type === '1Y' ? 'bg-[#ecc032] text-white' : ''}`} onClick={()=>onPress('1Y')}>
                <p className='text-xs font-medium capitalize tracking-tight'>1Y</p>
              </div>
              <div className={`px-3 py-2 periodTabItem border-r border-gray-200 transition-colors cursor-pointer text-[#757575] hover:bg-[#ecc032] hover:text-white ${type === 'All' ? 'bg-[#ecc032] text-white' : ''}`} onClick={()=>onPress('All')}>
                <p className='text-xs font-medium capitalize tracking-tight'>All</p>
              </div>
              </div>
            <div className='relative flex  bg-white border border-gray-200 rounded-md overflow-hidden'>
              <div onClick={()=>onPress(true)} className='border-r'>
              {/* <Input type='date' placeholder={'Select Start Date'} value={startDate} setValue={(value)=>setStartDate(value)}/> */}
              <input className='px-3 py-2 periodTabItem mb-0 text-[12px]' type='date' placeholder={'Select Start Date'} value={startDate} onChange={(e)=>setStartDate(e.target.value)}/>
              </div>
              <div onClick={()=>onPress(true)}>
              <input className='px-3 py-2 periodTabItem mb-0 text-[12px]' type='date' placeholder={'Select End Date'} value={endDate} onChange={(e)=>setEndDate(e.target.value)}/>
              </div>
              </div>
        </div>
        </div>
        <div className='grid grid-cols-4 gap-3 mt-4'>
          <div className='col-span-1 w-auto h-auto'>
            <StatusOrders data={analytics?.orderStatuses}/>
          </div>
          <div className='col-start-2 col-span-2 w-auto h-auto'>
            <CityOrders data={analytics?.cityOrders}/>
          </div>
          <div className='col-start-4 col-span-1 w-auto h-full'>
            <ProductOrders/>
          </div>

        </div>
    </div> : <Loader/>
  )
}


