// testing
// export const firebaseConfig = {
//     apiKey: "AIzaSyBigNFl1IzKW8vS4ODfVOsUnRYr7fadqLY",
//   authDomain: "coccarr-admin.firebaseapp.com",
//   projectId: "coccarr-admin",
//   storageBucket: "coccarr-admin.appspot.com",
//   messagingSenderId: "579228900453",
//   appId: "1:579228900453:web:de7c5aa0e94dc83a3eba8c",
//   measurementId: "G-LTF8XPM7VH"
//   };

  // live

  
export const firebaseConfig = {
  apiKey: "AIzaSyAMwHRzNyaKA-mrZgPvW6oXkWJ-sOKeKTM",
  authDomain: "cocarr-admin.firebaseapp.com",
  projectId: "cocarr-admin",
  storageBucket: "cocarr-admin.appspot.com",
  messagingSenderId: "516754459478",
  appId: "1:516754459478:web:d70e79cd7a8e4df54564f7"
};