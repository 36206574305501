import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
// import SearchInput from '../../components/SearchInput'
import authAxios from '../../helpers/axios'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { InfoToast } from '../../helpers/toasters'
import ManageAdmin from './components/ManageAdmin'
import { LIMIT } from '../../helpers/constants'
import RightIcon from '../../images/right.svg'
import SearchInput from '../../components/SearchInput'

export default function Admin() {

    const [searchText,setSearchText] = useState('')
    const [admin,setAdmins] = useState([])
    const [showCreate,setShowCreate] = useState({status:false,edit:null})
    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(5)

    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])
    const navigate = useNavigate()
    

    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})
    const RightContent = ()=>
    {
        return <div>
          <button type='button' className='btn-md' onClick={()=>setShowCreate({status:true,edit:null})}>Add Admin</button>
        </div>
    }

    async function getAdmins(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/admin?populate=true`)
            if(res.data) setAdmins(res.data)
        } catch (error) {
            toast('Error getting products')
        }
    }
    useEffect(()=>
    {
        getAdmins();
    },[])

    // useEffect(()=>
    // {
    //     async function getCities(){
    //         let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/city`)
    //         setCityFilters(res.data.data)
    //     }
    //     getCities()
    // },[])

    // useEffect(()=>
    // {
    //     async function getRoutes(){
    //         let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/route?routeCity=${selectedFilters.city}`)
    //         if(res.data.data)  setRouteFilters(res.data.data)
    //     }
    //     if(selectedFilters.city !== '') getRoutes()
    //     else {
    //         setRouteFilters([]);
    //         setSelectedFilters(data=>({...data,route:''}))
    //     }
    // },[selectedFilters.city])

    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            let res;
            if(showCreate.edit)
            {
                res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/admin/${showCreate.edit}`,data) 
                InfoToast('Admin Updated')
                
            }
            else
            {
                res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/admin`,data)
                InfoToast('Admin Created')
            }
            if(res.data)
            {
                await getAdmins()
                setShowCreate({status:false,edit:null})
            }
            else toast('error getting admins')
        } catch (error) {
            // console.log(error.response.data.error[0])
            toast.error(error.response.data.error[Object.keys(error.response.data.error)[0]])
        }
    }


  return (
    <div className='max-w-7xl mx-auto'>
        {/* <Header title={'Admin Management'} RightContent={RightContent}/> */}
        <div className='flex justify-between items-stretch '>
        <div className='flex mb-4'>
            <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search vendor'} label={'Search vendor'} />
            <div className='flex w-full items-center h-full justify-center text-center border-[#d3d3d3] bg-white rounded-md shadow-sm shadow-gray-200 ml-4'>
                <div
                    className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center'
                    onClick={() => {
                    if (offset > 0) {
                        setOffset(offset - LIMIT);
                    }
                    }}
                >
                    <img src={RightIcon} className='w-[20px] h-[20px] rotate-180' alt='Previous Page' />
                </div>
                <p className='text-xs whitespace-nowrap w-[80px] justify-center'>{offset + 1}-{Math.min(offset + LIMIT, count)} of {count}</p>
                <div
                    className='px-2 py-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center'
                    onClick={() => {
                    if (offset + LIMIT < count) {
                        setOffset(offset + LIMIT);
                    }
                    }}
                >
                    <img src={RightIcon} className='w-[20px] h-[20px]' alt='Next Page' />
                </div>
                </div>
        </div>
                    <div>
                    <button type='button' className='btn-md' onClick={()=>setShowCreate({status:true,edit:null})}>Add Admin</button>
                    </div>
        {/* <div className='flex justify-end h-[100%] self-stretch items-center px-4 border-l border-[#e3e3e3] hover:bg-[#f6f6f6] cursor-pointer' onClick={()=>setShowFilters(true)}>
        {(filters.cityId) ? <img src={FilterActiveIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> : <img src={FilterIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> }
        </div> */}
    </div>
        <div className='flex flex-1 bg-white w-full'>
            <table className=' w-full'>
                <thead className='bg-[#fff] w-full'>
                    <tr className='w-full'>
                    <td><p>Name</p></td>
                    <td><p>Email</p></td>
                    <td><p>MobileNumber</p></td>
                    <td><p>Last Activity</p></td>
                    <td><p>Role</p></td>
                    <td><p>City</p></td>
                    <td><p>Action</p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        admin.map((item,index)=>
                        {
                            return <tr>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.name}</p>
                                        {/* <p className='text-xs my-0 text-gray-400'>{item.productSku}</p> */}
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm lowercase font-regular my-0'>{item.email}</p>
                                        {/* <p className='text-xs my-0 text-gray-400'>{item.productSku}</p> */}
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.mobile}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.productPrice}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.productWeight}</p>
                                    {/* <button className='btn-xs' onClick={()=>navigate(`/retailers/${item.id}`)}>View</button> */}
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.productReward}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                    <button className='btn-xs' onClick={()=>setShowCreate({status:true,edit:item.id})}>Edit</button>
                                    </div>
                                </td>

                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
        { showCreate.status ? <ManageAdmin onClose={setShowCreate} onSubmit={onSubmit} edit={showCreate.edit}/> : null}
    </div>
  )
}

