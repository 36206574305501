import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
// import SearchInput from '../../components/SearchInput'
import authAxios  from '../../helpers/axios'
import SortIcon  from '../../images/sort.svg'
import RightIcon  from '../../images/right.svg'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { InfoToast } from '../../helpers/toasters'
import ManageVendor from './components/ManageVendor'
import { LIMIT } from '../../helpers/constants'
import { getDateFormat, getTimeFormat } from '../../helpers/utils'
import SearchInput from '../../components/SearchInput'
// import Table from '../../components/Table'

export default function Vendors() {

    const [searchText,setSearchText] = useState('')
    const [vendors,setVendors] = useState([])
    const [showManage,setShowManage] = useState({status:false,edit:null})
    const [sort,setSort] = useState('-createdOn')
    const navigate = useNavigate()
    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(5)
    const [summary,setSummary] = useState({pendingOrders:0,totalOrders:0,cancelledOrders:0,totalAmount:0,totalQty:0})
    const [filters,setFilters] = useState({cityId:null,cityId:null,status:null,distributorId:null,retailerId:null,teamId:null,salesRepId:null,startDate:null,endDate:null})
    const [showFilters,setShowFilters] = useState(false)
    const [submitting,setSubmitting] = useState(false)

    

    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})
    const RightContent = ()=>
    {
        return <div>
          <button type='button' className='btn-md' onClick={()=>setShowManage({status:true,edit:null})}>Add Vendor</button>
        </div>
    }

    async function getVendors(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/vendor?populate=true`)
            if(res.data) setVendors(res.data)
        } catch (error) {
            toast('Error getting products')
        }
    }
    useEffect(()=>
    {
        getVendors();
    },[])

    const onFilterSubmit = (filters)=>
    {
        try 
        {
            setFilters({...filters})
            setCount(5)
            setOffset(0)
            setShowFilters(false)
        } catch (error) {
            toast('Order Filtered')
        }
    }

    const clearFilter = ()=>
    {
        try 
        {
            setFilters({routeId:null,cityId:null,status:null,distributorId:null,retailerId:null,teamId:null,salesRepId:null,startDate:null,endDate:null})
            setShowFilters(false)
        } catch (error) {
            toast('Order Filtered')
        }
    }

    const onSortPress = (type)=>
    {
        if (sort === type) {
            if(sort === `-${type}`) setSort(type)
            else setSort(`-${type}`);
          } else {
            setSort(type);
          }
    }



    const onSubmit = async(e,data,croppedImage)=>
    {
        try 
        {
            e.preventDefault()
            let res;
            if(showManage.edit)
            {
                res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/vendor/${showManage.edit}`,data) 
                InfoToast('Vendor Updated')
                
            }
            else
            {
                res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/vendor`,data)
                InfoToast('Vendor Created')
            }
            if(res.data)
            {
                await getVendors()
                setShowManage({status:false,edit:null})
            }
            else toast('error updating vendor')
        } catch (error) {
            // console.log(error.response.data.error[0])
            InfoToast(error.response.data.error[Object.keys(error.response.data.error)[0]])
        }
    }

    const columns = [
        {
          Header: 'ID',
          accessor: 'id',
        },
        {
          Header: 'Name',
          accessor: 'name',
        },
        // Add more columns as needed
      ];

      const data = [
        { id: 1, name: 'John' },
        { id: 2, name: 'Jane' },
        // Add more data rows as needed
      ];
    

      

  return (
    <>
    {/* <Header title={'Vendor Management'} RightContent={RightContent}/> */}
    <div className='flex-1 max-w-7xl mx-auto'>
    <div className='flex justify-between items-stretch '>
        <div className='flex mb-4'>
            <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search vendor'} label={'Search vendor'} />
            <div className='flex w-full items-center h-full justify-center text-center border-[#d3d3d3] bg-white rounded-md shadow-sm shadow-gray-200 ml-4'>
                <div
                    className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center'
                    onClick={() => {
                    if (offset > 0) {
                        setOffset(offset - LIMIT);
                    }
                    }}
                >
                    <img src={RightIcon} className='w-[20px] h-[20px] rotate-180' alt='Previous Page' />
                </div>
                <p className='text-xs whitespace-nowrap w-[80px] justify-center'>{offset + 1}-{Math.min(offset + LIMIT, count)} of {count}</p>
                <div
                    className='px-2 py-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center'
                    onClick={() => {
                    if (offset + LIMIT < count) {
                        setOffset(offset + LIMIT);
                    }
                    }}
                >
                    <img src={RightIcon} className='w-[20px] h-[20px]' alt='Next Page' />
                </div>
                </div>
        </div>
                    <div>
                    <button type='button' className='btn-md' onClick={()=>setShowManage({status:true,edit:null})}>Add Vendor</button>
                    </div>
        {/* <div className='flex justify-end h-[100%] self-stretch items-center px-4 border-l border-[#e3e3e3] hover:bg-[#f6f6f6] cursor-pointer' onClick={()=>setShowFilters(true)}>
        {(filters.cityId) ? <img src={FilterActiveIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> : <img src={FilterIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> }
        </div> */}
    </div>
    <div className='w-full flex-1 flex overflow-scroll h-full border-[#d3d3d3] bg-white rounded-lg shadow-sm shadow-gray-200'>
        <table className=' table-auto w-full flex-1 border-0 h-full'>
            <thead className='bg-[#f9f9f9] w-full'>
                <tr className='w-full'>
            
                <td><p onClick={()=>onSortPress('name')} className='cursor-pointer flex items-center hover:text-black'>Name {sort === 'name' || sort === '-name'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>

                <td><p onClick={()=>onSortPress('email')} className='cursor-pointer flex items-center hover:text-black'>Email {sort === 'email' || sort === '-email'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>

                <td><p onClick={()=>onSortPress('mobile')} className='cursor-pointer flex items-center hover:text-black'>Mobile {sort === 'mobile' || sort === '-mobile'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>

                <td><p onClick={()=>onSortPress('city')} className='cursor-pointer flex items-center hover:text-black'>City {sort === 'city' || sort === '-city'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>

                <td><p onClick={()=>onSortPress('createdOn')} className='cursor-pointer flex items-center hover:text-black'>Created On {sort === 'createdOn' || sort === '-createdOn'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>

                <td><p onClick={()=>onSortPress('status')} className='cursor-pointer flex items-center hover:text-black'>Status {sort === 'status' || sort === '-status'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                </tr>
            </thead>
            <tbody>
                {
                    vendors.map((item,index)=>
                    {
                        return <tr>
                            <td className='capitalize'>
                                <div>
                                    <p className='text-sm font-regular my-0'>{item.name}</p>
                                </div>
                            </td>
                            <td >
                            <div>
                                    <p className='text-sm font-regular my-0'>{item.email}</p>
                                </div>
                            </td>
                            <td className='capitalize'>
                                <div>
                                    <p className='text-sm font-regular my-0'>{item.mobile}</p>
                                </div>
                            </td>
                            <td className='capitalize'>
                                <div>
                                    <p className='text-sm font-regular my-0'>{item.cityName?.name}</p>
                                </div>
                            </td>
                            <td className='capitalize'>
                                <div>
                                    <p className='text-sm font-regular my-0'>{getDateFormat(item.createdAt)}</p>
                                </div>
                            </td>
                            <td className='capitalize'>
                            <div>
                                    <p className={`text-[13px] font-semibold px-3 inline-block py-1 rounded-md ${item.isActive ? 'bg-[#39C7A5] bg-opacity-50 text-green-700 ' : 'text-red-700 bg-red-200 bg-opacity-10'}}`}>{item.isActive ? 'Active' : 'Inactive'}</p>
                                </div>
                            </td>
                        </tr>
                    })
                }
            </tbody>
        </table>
        {/* <Table columns={columns} data={data} /> */}
    </div>
    </div>
    { showManage.status ? <ManageVendor submitting={submitting} onClose={setShowManage} edit={showManage.edit} onSubmit={onSubmit}/> : null}
    {/* { vieworder ? <ViewOrder onClose={setVieworder} id={vieworder}/> : null} */}
    {/* { showFilters ? <FilterOrder onSubmit={onFilterSubmit} id={vieworder} filters={filters} onClose={setShowFilters} clearFilter={clearFilter}/> : null} */}
</>
  )
}

