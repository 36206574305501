import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import RightIcon from '../../images/right.svg'
import authAxios from '../../helpers/axios'
import { toast } from 'react-toastify'
import { ErrorToast, InfoToast } from '../../helpers/toasters'
import SearchInput from '../../components/SearchInput'
import {DUE_CANCELLED, DUE_CREATED, DUE_PAID, LIMIT } from '../../helpers/constants'
import { getValidDateFormat } from '../../helpers/utils'
import Status from '../../components/Status'
import ManageDue from './components/ManageDue'

export default function Dues() {

    const [searchText,setSearchText] = useState('')
    const [dues,setDues] = useState([])
    const [showManage,setShowManage] = useState({status:false,edit:null})
    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(5)
    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])
    const [submitting,setSubmitting] = useState(false)
    const navigate = useNavigate()
    

    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})
    const RightContent = ()=>
    {
        return null
    }

    async function getDues(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/due?populate=true`)
            console.log('data',res.data)
            if(res.data) setDues(res.data)
        } catch (error) {
            toast('Error getting dues')
        }
    }
    useEffect(()=>
    {
        getDues();
    },[])


    const onClick = (id)=>
    {
        navigate(`/dues/${id}`)
    }

    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            let res;
            setSubmitting(true)
            if(showManage.edit)
            {
                res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/due/${showManage.edit}`,data) 
                InfoToast('Due Updated')
                
            }
            else
            {
                res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/due`,data)
                InfoToast('Due Created')
            }
            if(res.data)
            {
                await getDues()
                setSubmitting(false)
                setShowManage({status:false,edit:null})
            }
            else 
            {
                ErrorToast('error updating due')
                setSubmitting(true)
            }
        } catch (error) {
            setSubmitting(false)
            // console.log(error.response.data.error[0])
            InfoToast(error.response.data.error[Object.keys(error.response.data.error)[0]])
        }
    }



  return (
    <div className='max-w-7xl mx-auto px-6'>
            <div className='flex justify-between items-stretch '>
        <div className='flex mb-4'>
            <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search due'} label={'Search due'} />
            <div className='flex w-full items-center h-full justify-center text-center border-[#d3d3d3] bg-white rounded-md shadow-sm shadow-gray-200 ml-4'>
                <div
                    className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center'
                    onClick={() => {
                    if (offset > 0) {
                        setOffset(offset - LIMIT);
                    }
                    }}
                >
                    <img src={RightIcon} className='w-[20px] h-[20px] rotate-180' alt='Previous Page' />
                </div>
                <p className='text-xs whitespace-nowrap w-[80px] justify-center'>{offset + 1}-{Math.min(offset + LIMIT, count)} of {count}</p>
                <div
                    className='px-2 py-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center'
                    onClick={() => {
                    if (offset + LIMIT < count) {
                        setOffset(offset + LIMIT);
                    }
                    }}
                >
                    <img src={RightIcon} className='w-[20px] h-[20px]' alt='Next Page' />
                </div>
                </div>
        </div>
        <div>
                    <button type='button' className='btn-md' onClick={()=>setShowManage({status:true,edit:null})}>Add Due</button>
                    </div>
    </div>
        <div className='flex bg-white rounded-md flex-1  w-full'>
            <table className=' w-full'>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>
                    <td><p>User</p></td>
                    <td><p>Booking Id</p></td>
                    <td><p>Reason</p></td>
                    <td><p>Amount(In Rs.)</p></td>
                    <td><p>Status</p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        dues.map((item,index)=>
                        {
                            return <tr onClick={()=>onClick(item.id)}>
                                <td className='capitalize'>
                                    <div>
                                        <p className='text-sm font-medium my-0'>{item.user.name ? item.user.name : 'Unavailable'}</p>
                                        {/* <p className='text-xs my-0 text-gray-400'>{getValidDateFormat(item.createdAt)}</p> */}
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-medium my-0'>{item.bookingId ? item.booking.bookingId : 'Unavailable'}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-medium my-0'>{item.reason}</p>
                                        {item.cancelledReason ? <p className='text-xs text-red-600 font-medium my-0'>{item.cancelledReason}</p> : null}
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>Rs.{item.totalAmount}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                    <Status label={item.status} type={item.status === DUE_CREATED ? 'neutral' : item.status === DUE_CANCELLED ? 'negative' : 'positive'}/>
                                {/* <div>
                                        <p className='text-sm font-medium my-0 uppercase'>{item.status}</p>
                                    </div> */}
                                </td>

                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
        { showManage.status ? <ManageDue submitting={submitting} onClose={setShowManage} onSubmit={onSubmit} edit={showManage.edit}/> : null}
    </div>
  )
}

