import { useEffect, useState } from "react"
import authAxios from '../../helpers/axios'
import { toast } from "react-toastify";
import TickIcon from "../../images/tick.svg";
import { useOutletContext } from "react-router-dom";
import Popup from "../../components/Popup";
import { calculateAge } from "../../helpers/utils";

export default function UserProfile()
{
    const { id } = useOutletContext();
    const [info,setInfo] = useState({})
    const [cities,setCities] = useState('')
    const [showKycUpdate,setShowKycUpdate] = useState(false)
    const [showLicenseUpdate,setShowLicenseUpdate] = useState(false)



    async function getUserInfo(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/admin/user/${id}?populate=true`)
            if(res.data) setInfo(res.data)
        } catch (error) {
            toast('Error getting Users')
        }
    }
    useEffect(()=>
    {
        getUserInfo();
    },[])

    // const onSubmit = async(e,data)=>
    // {
    //     try 
    //     {
    //         e.preventDefault()
    //         console.log('checking',!data.cities || data.cities.length <= 0 || data.cities.some(route => route.routes && route.routes.length <= 0))
    //         if(!data.cities || data.cities.length <= 0 || data.cities.some(route => route.routes && route.routes.length <= 0))
    //         {
    //           toast.error('Select Atleast One Route')
    //           return true;
    //         }
    //         else
    //         {
    //             setSubmitting(true)
    //             let res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/user/${id}?populate=true`,data) 
    //             if(res.data.data)
    //             {
    //                 await getUserInfo()
    //             }
    //             else toast('error updating retailer')
    //             setShowEdit(false)
    //             setSubmitting(false)
    //         }
    //     } catch (error) {
    //         setSubmitting(false)
    //         toast(error.response.data)
    //     }
    // }




    return <div>
    {/* <div className=' justify-start items-start self-stretch w-full'> */}
        <div className='bg-white rounded-md shadow-sm px-6 py-4'>
            <div className='pb-3 border-b border-b-slate-200 flex w-full justify-between'>
            <p className='text-sm font-medium text-[#959595] '>Personal Information</p>
            <button className='btn-xs'>Update Plan</button>
            </div>
        <div className='grid grid-cols-4 gap-6 pt-6'>
            <div className="col-span-4">
                <div className="w-[80px] h-[80px] rounded-full overflow-hidden">
                    <img src={info?.profilePhoto} className="w-full h-full"/>
                </div>
            </div>
            <div>
                <label>Full Name</label>
                <p className='text-sm'>{info?.name}</p>
            </div>
            <div>
                <label>Email</label>
                <p className='text-sm'>{info?.email}</p>
            </div>
            <div>
                <label>Contact Number</label>
                <p className='text-sm'>{info?.contactNumber}</p>
            </div>
            <div>
                <label>City</label>
                {/* <p className='text-sm'>{cities}</p> */}
            </div>
            </div>
        </div>
        
        <div className='bg-white rounded-md shadow-sm px-6 py-4 mt-4'>
            <div className='pb-3 border-b border-b-slate-200 flex w-full justify-between'>
            <p className='text-sm font-medium text-[#959595] '>Ride Information</p>
            <button className='btn-xs'>Update Info</button>
            </div>
        <div className='grid grid-cols-4 gap-6 pt-6'>
            <div>
                <label>Total Rides</label>
                <p className='text-sm'>{info?.totalRides}</p>
            </div>
            <div>
                <label>Total Clean Rides</label>
                <p className='text-sm'>{info?.totalUncleanRides}</p>
            </div>
            <div>
                <label>Deposit Free</label>
                <p className='text-sm'>{info?.isFreeDepositAllowed ? 'Allowed' : 'Not Allowed'}</p>
            </div>
            </div>
        </div>
        {/* </div> */}

        <div className='bg-white rounded-md shadow-sm px-6 py-4 mt-4'>
            <div className='pb-3 border-b border-b-slate-200 flex w-full justify-between'>
            <p className='text-sm font-medium text-[#959595] '>KYC Verification Information</p>
            {!info?.kycVerified ? <button className='btn-xs' onClick={()=>setShowKycUpdate(true)}>Verify KYC</button> : null}
            </div>
            <div className='grid grid-cols-3 gap-x-3 mt-8 gap-y-3'>
                <div>
                    <label>KYC Image</label>
                    <div className="w-full max-w-[200px] h-[120px] rounded-lg bg-[#f3f3f3]">
                        <img src={`${info?.kycImage}`} />
                    </div>
                </div>
                <div>
                    <label>KYC Number</label>
                    <p className='text-sm font-medium flex items-center'>{info?.kycNumber}<img src={TickIcon} className="w-[16px] h-[16px] ml-2"/></p>
                </div>
                <div>
                    <label>Manual Verificaiton</label>
                    <p className='text-sm font-medium'>{info?.kycVerified ? 'Verified' : 'Not Verified'}</p>
                </div>
            </div>
        </div>
        
        <div className='bg-white rounded-md shadow-sm px-6 py-4 mt-4'>
            <div className='pb-3 border-b border-b-slate-200 flex w-full justify-between'>
            <p className='text-sm font-medium text-[#959595] '>License Verification Information</p>
            {!info?.licenseVerified ?<button className='btn-xs' onClick={()=>setShowLicenseUpdate(true)}>Verify License</button> : null}
            </div>
            <div className='grid grid-cols-3 gap-x-3 mt-8 gap-y-3'>
                <div>
                    <label>License Front Image</label>
                    <div className="w-full max-w-[200px] h-[120px] rounded-lg bg-[#f3f3f3]">
                        <img src={`${info?.licenseFrontImage}`} />
                    </div>
                </div>
                <div>
                    <label>License Back Image</label>
                    <div className="w-full max-w-[200px] h-[120px] rounded-lg bg-[#f3f3f3]">
                        <img src={`${info?.licenseBackImage}`} />
                    </div>
                </div>
                <div>
                    <label>License Manual Verificaiton</label>
                    <p className='text-sm font-medium'>{info?.licenseVerified ? 'Verified' : 'Not Verified'}</p>
                </div>
            </div>
        </div>
        {showKycUpdate ? <KycVerification onSubmit={getUserInfo} id={id} onClose={()=>setShowKycUpdate(false)}/> : null}
        {showLicenseUpdate ? <LicenseVerification onSubmit={getUserInfo} id={id} frontImage={info?.licenseFrontImage} backImage={info?.licenseBackImage} onClose={()=>setShowLicenseUpdate(false)}/> : null}
    </div>
}


const LicenseVerification = ({onClose,id,frontImage,backImage,onSubmit})=>
{
    const [loading,setLoading] = useState(true)
    const [submitting,setSubmitting] = useState(false)


    async function verifyKyc(e){

        try 
        {
            e.preventDefault()
            setSubmitting(true)
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/admin/user/verify-license/${id}`)
            await onSubmit()
            onClose()
            setSubmitting(false)
    } catch (error) {
        console.log(error)
        setSubmitting(false)
            toast('Error getting Users')
        }
    }


    return <Popup title={'Verify License'} onClose={onClose} submitting={submitting} onSubmittingTitle="Verifying" formName={'verifyLicense'} submitTitle={'Verified'}>
        <form onSubmit={verifyKyc} id="verifyLicense" name="verifyLicense">
            <div className="grid grid-cols-2 gap-x-4">
                <div className="col-span-1">
                    <label>Front Image</label>
                    <div className="flex">
                        <img src={frontImage} className="h-[120px] w-[120px] mr-2 mt-2 rounded-sm"/>
                    </div>
                </div>
                <div>
                    <label>Back Image</label>
                    <div className="flex">
                        <img src={backImage} className="h-[120px] w-[120px] mt-2 rounded-sm"/>
                    </div>
                </div>
            </div>
        </form>
    </Popup>
}



const KycVerification = ({onClose,id,onSubmit})=>
{
    const [loading,setLoading] = useState(true)
    const [submitting,setSubmitting] = useState(false)
    const [info,setInfo] = useState()
    async function getKycInfo(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/admin/user/kyc-info/${id}`)
            if(res.data) setInfo(res.data)
            setLoading(false)
    } catch (error) {
            setLoading(false)
            toast('Error getting Users')
        }
    }
    useEffect(()=>
    {
        getKycInfo();
    },[])

    async function verifyKyc(e){

        try 
        {
            e.preventDefault()
            setSubmitting(true)
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/admin/user/verify-kyc/${id}`)
            await onSubmit()
            onClose()
            setSubmitting(false)
    } catch (error) {
        console.log(error)
        setSubmitting(false)
            toast('Error getting Users')
        }
    }


    return <Popup title={'Verify KYC'} onClose={onClose} formName={'verifyKyc'} submitTitle={'Verified'}>
        <form onSubmit={verifyKyc} id="verifyKyc" name="verifyKyc">
            <div className="grid grid-cols-2 gap-x-4 w-full">
                <div className="col-span-2 mb-6">
                    <label>Name</label>
                    <p className="text-sm">{info?.user.name}</p>
                    <p className="text-xs text-green-700">{info?.kycInfo.name}</p>
                </div>
                <div className="col-span-1">
                    <label>KYC Photo</label>
                    <div className="flex">
                        <img src={`data:image/jpeg;base64,${info?.kycInfo.photo_link}`} className="h-[120px] w-[120px] mr-2 mt-2 rounded-sm"/>
                    </div>
                </div>
                <div>
                    <label>Profile Photo</label>
                    <div className="flex">
                        <img src={info?.user.profilePhoto} className="h-[120px] w-[120px] mt-2 rounded-sm"/>
                    </div>
                </div>
                <div className="col-span-2 mt-6 mb-4">
                    <label>DOB/Gender</label>
                    <p className="text-sm ">{info?.kycInfo.dob} ({calculateAge(info?.kycInfo.dob)} Yrs Old)</p>
                    <p className="text-xs text-green-700">{info?.kycInfo.gender === 'M' ? 'Male' : info?.kycInfo.gender === 'F' ? 'Female' : 'Others'}</p>
                </div>
                <div className="col-span-2">
                    <label>Address</label>
                    <p className="text-sm ">{info?.kycInfo.address}</p>
                </div>
            </div>
        </form>
    </Popup>
}