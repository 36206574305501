import React, { useEffect, useState } from 'react'
import Logo from '../../images/logo.png'
import { Link, useNavigate } from 'react-router-dom'
import InputGroup from '../../components/InputGroup'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../../redux/slice/authSlice'
import { toast } from 'react-toastify'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'

export default function Login() {

    const [data,setData] = useState({adminEmail:'',adminPassword:''})
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading,setLoading] = useState(false)
    const [error,setError] = useState(null)
    const authInfo = useSelector(state=>state.auth)

    useEffect(()=>
    {
        if(authInfo.token && authInfo.isLoggedIn) return navigate('/')
        
    },[])
    const onSubmit = async(e)=>
    {
        try {
            let auth = getAuth();
            setError(false)
            e.preventDefault();
            setLoading(true)
            await signInWithEmailAndPassword(auth,data.adminEmail, data.adminPassword)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                // ...
              })
              .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                throw {message:errorMessage,code:errorCode}
              });
            const user = auth.currentUser;
                if (user) {
                // Get the user's ID token
                const token = await user.getIdToken();
                console.log('User token:', token);
                dispatch(login({...user,token:token}))
                
                // if(authInfo.token && authInfo.isLoggedIn)
                // {
                //     await authAxios.interceptors.request.use(
                //         async(config) => {
                //             config.headers.Authorization = authInfo.token;
                //             console.log('axis running inside')
                //             return config;
                //         }
                //         ,(error) => {
                //             console.log('Token error',error.message)
                //             return Promise.reject(error)
                //         });
                //     }
                    return navigate('/')
                }
            } catch (error) {
                setLoading(false)
                console.log(error)
                toast(error.errorMessage);
                
            }
                // else {
                //     setLoading(false)
                //     setError(res.errors.invalid_credentials)
                // }
            }

            return (
                <div className='flex md:h-screen justify-center'>
        <div className='md:col-span-3 md:h-screen w-full'>
            <div className='h-auto md:h-full w-full flex py-8'>
                <div className="my-auto  mx-auto w-full max-w-md px-12 py-12 rounded-lg bg-white border-gray-100 border">
                    <img src={Logo} className='mx-auto w-auto h-12 mb-2'/>
                    <h1 className='text-xl font-bold text-center'>Hi, Welcome Back</h1>
                    <p className='text-sm text-gray-500 text-center'>to the cocarr admin portal</p>
                    
                    <form className='mt-12 w-full' onSubmit={onSubmit}>
                        <InputGroup type='email' label="Email Address" placeholder="johndoe@workemail.com" value={data.adminEmail} setValue={(value)=>setData(data=>({...data,adminEmail:value}))} required={true}/>
                        <InputGroup type='password' label="Password" placeholder="8+ Characters required"  value={data.adminPassword} setValue={(value)=>setData(data=>({...data,adminPassword:value}))} required={true}/>
                        <div className='flex justify-between'>
                            {/* <Toggle/> */}
                            <Link className='text-sm text-blue-700 font-medium'>Forgot Password</Link>
                        </div>
                        <div className='relative mt-4'>
                            <button type='submit' className='btn-xl w-full mt-4 mb-6' disabled={loading}>{loading ? 'Logging In ': 'Login'}</button>
                            {error ? <div class="w-full absolute left-0 bottom-0 flex items-center pointer-events-none overflow-hidden h-4">
                                <div className='text-center w-full'>
                            <p className='text-red-600 h-[20px] text-[14px] tracking-tight font-medium'>{error}</p>
                                    </div>
                        </div> : null}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}
