import React, { useEffect, useState } from 'react'
import { Link, NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import Logo from '../images/logo.png'
// import Menu from '../images/menu-icon.svg'
import DashboardIcon from '../images/dashboard.svg'
import OrdersIcon from '../images/dashboard.svg'
import SalesRepIcon from '../images/dashboard.svg'
import ProductsIcon from '../images/dashboard.svg'
import SettingsIcon from '../images/settings.svg'
import LogoutIcon from '../images/logout.svg'
import LeaderboardIcon from '../images/dashboard.svg'
import UpIcon from '../images/up.svg'
import RetailerIcon from '../images/dashboard.svg'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../redux/slice/authSlice'
import { getAuth, onAuthStateChanged } from "firebase/auth";


const SidebarMenuSubItem = ({active,setActive,data,collapsed})=>
{
    return  <div className={`pl-4 menu-sub-item relative my-3 mb-2 ${collapsed ? 'pl-0' : 'pl-4'}`}>
        <NavLink to={data.url} className={({ isActive, isPending }) =>
    isActive ? " inline-block menu-sub-active group active" : "inline-block"
  }>
            <div className={`w-auto mx-auto flex rounded-md items-center group-[.menu-sub-active]:bg-[#313B53] transition-all duration-[300ms] ${collapsed ? 'ml-4 px-4' : 'ml-10 px-4'}`}>
            <div className='py-3'>
            </div>
            <div className={`${!collapsed ? 'ml-3' :'ml-0'}`}>
                <p className={`text-[13px] whitespace-nowrap font-normal transition-all duration-[300ms] text-gray-300 group-[.active]:text-white ${!collapsed ? 'w-auto opacity-100' :'w-0 overflow-hidden opacity-0'}`}>{data.label}</p>
            </div>
        </div>
        </NavLink>
        </div>
}

const SidebarMenuItem = ({active,setActive,data,collapsed})=>
{
    return  <div className={`menu-item transition-all relative  ${active === data.id && data.childrens && data.childrens.length >0 ? 'menu-item-active ' : ''}`} onClick={()=>setActive(data.id)}>
        <NavLink to={data.url} className={({ isActive, isPending }) =>
        {
            if(isActive) setActive(data.id);
            return isActive ? " block group parent-active opacity-100" : "filter grayscale"
        }
}>
            <div className={`w-full flex px-2 items-center rounded-md`}>
      {/* {active === data.id ? <div className='h-full w-[6px] bg-[#ECC032] absolute top-0 left-0 rounded-r-full'></div> : null} */}
                <div className='w-full flex items-center'>
                    {/* <div className='py-4'>
                        <img src={data.icon} className='w-4 h-4 '/>
                    </div> */}
                    {!collapsed ? <div className='ml-4'>
                        <p className='text-[13px] py-2 tracking-[-.15px] font-normal text-[#ECC032] '>{data.label}</p>
                        {/* <p className='text-[13px] tracking-[-.15px] font-medium text-[#757575] hover:text-[#ECC032] group-[.parent-active]:text-[#ECC032]'>{data.label}</p> */}
                    </div> : null}
                </div>
                {!collapsed && data.childrens && data.childrens.length > 0 ? <div className=''>
                    <img src={UpIcon} className={`w-3 h-3 ${active !== data.id ? 'origin-center rotate-180' : ''}`}/>
                </div> : null}
        </div>
        </NavLink>
        {
            data.childrens && data.childrens.length > 0 ? <div className={`overflow-hidden transition-all ${active !== data.id ? 'h-0 ' : 'h-full py-4'}`}>
            {
                data.childrens.map((item,index)=>
                {
                    return <SidebarMenuSubItem data={item} key={index} active={active} collapsed={collapsed}/>
                })
            }
        </div> : null
        }
        </div>
}
export default function Sidebar() {

    const [activeMenu,setActiveMenu] = useState(1)
    const [collapsed,setCollapsed] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const authInfo = useSelector(state=>state.auth)
    const [loading,setLoading] = useState(true)

    const {pathname} = useLocation();

    useEffect(()=>{
        if(!authInfo.isLoggedIn || !authInfo.token) return navigate('/login')
    },[authInfo])

    const onLogout = ()=>
    {
        dispatch(logout())
    }

    const data = [
        {
            id:1,
            label:'Dashboard',
            icon:DashboardIcon,
            childrens:null,
            url:'/'
        },
        {
            id:2,
            label:'Rides',
            icon:OrdersIcon,
            childrens:null,
            url:'/rides'
        },
        {
            id:3,
            label:'Dues',
            icon:SalesRepIcon,
            url:'/dues',
        },
        {
            id:3,
            label:'Payments',
            icon:SalesRepIcon,
            url:'/payments',
        },
        {
            id:3,
            label:'Memberships',
            icon:SalesRepIcon,
            url:'/memberships',
        },
        {
            id:4,
            label:'Users',
            icon:ProductsIcon,
            childrens:null,
            url:'/users'
        },
        {
            id:5,
            label:'Vendors',
            icon:RetailerIcon,
            childrens:null,
            url:'/vendors'
        },
        {
            id:6,
            label:'Admins',
            icon:LeaderboardIcon,
            childrens:null,
            url:'/admins'
        },
        {
            id:7,
            label:'Vehicles',
            icon:SalesRepIcon,
            childrens:null,
            url:'/vehicles'
        },
        {
            id:8,
            label:'Settings',
            icon:SettingsIcon,
            childrens:null,
            url:'/settings/'
        }
        
    ]

    
      useEffect(() => {
        const auth = getAuth()
        const unsubscribe = onAuthStateChanged(auth,(user) => {
          if (user) {
            setLoading(false)
        } else {
              setLoading(false)
            
          }
        });
    
        return () => unsubscribe(); // Unsubscribe when the component unmounts
      }, []); // Empty dependency array ensures the effect runs once

  return (
    !loading ? <div className='w-full h-[100%] block items-stretch transition-all bg-[#F5F5F5]'>
        <div className={`fixed top-0 z-40 md:flex bg-[#151515] w-full`}>
            <div className="flex mx-auto max-w-7xl w-full">
            <div className='flex  w-full px-8 py-0 items-center transition-all duration-[300ms]'>
                {!collapsed ? <img src={Logo} className='h-[40px] w-auto items-center'/> : null}
            </div>
            <div className='w-auto flex py-4 px-6'>
                {
                    data.map((item,index)=>
                    {
                        return <SidebarMenuItem collapsed={collapsed} data={item} key={index} setActive={setActiveMenu} active={activeMenu}/>
                    })
                }
            </div>
            <div className={`w-full flex px-4 items-center cursor-pointer  filter grayscale hover:grayscale-0`} onClick={()=>onLogout()}>
                <div className='w-full flex items-center'>
                    <div className='py-4'>
                        <img src={LogoutIcon} className='w-4 h-4 '/>
                    </div>
                    <div className='ml-4'>
                        <p className='text-[12px] tracking-[-.15px] font-medium text-[#ECC032] '>Logout</p>
                    </div>
                </div>
        </div>
        </div>
        </div>
        <div className='flex-1 pt-4  md:pt-24 h-screen w-full overflow-hidden overflow-y-scroll '>
            <Outlet/>
        </div>
    </div> : <p>Loading</p>
  )
}
