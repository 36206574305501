import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
// import SearchInput from '../../components/SearchInput'
import authAxios from '../../helpers/axios'
import { toast } from 'react-toastify'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { InfoToast } from '../../helpers/toasters'
import { LIMIT } from '../../helpers/constants'

export default function UserPayments() {

    const { id } = useOutletContext();
    const [searchText,setSearchText] = useState('')
    const [rides,setRides] = useState([])
    const [showCreate,setShowCreate] = useState({status:false,edit:null})

    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])
    const navigate = useNavigate()
    const [offset,setOffset] = useState(0)
    const [count,setCount] = useState(0)
    

    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})
    const RightContent = ()=>
    {
        return null
    }

    async function getPayments(){

        try 
        {
            let query = `populate=true&offset=${offset}&limit=${LIMIT}&userId=${id}`
            if(searchText) query+= `&search=${searchText}`
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/transaction?${query}`)
            console.log('data',res.data)
            setRides(res.data.data)
            setCount(res.data.totalCount)
        } catch (error) {
            toast('Error getting products')
        }
    }
    useEffect(()=>
    {
        getPayments();
    },[])




  return (
    <>
        <div className='flex flex-1 bg-white w-full'>
            <table className=' w-full'>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>
                    <td><p>Order Type</p></td>
                    <td><p>Order Id /Payment Id</p></td>
                    <td><p>Payment Method</p></td>
                    <td><p>Total Amount</p></td>
                    <td><p>Status</p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        rides.map((item,index)=>
                        {
                            return <tr>
                                <td className='capitalize'>
                                    <div>
                                         <p className='text-sm font-semibold my-0 uppercase'>{item.type}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.orderId}</p>
                                        <p className='text-xs my-0 text-gray-400'>{item.paymentId}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0 uppercase'>{item.paymentMethod ? item.paymentMethod : '-'}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>Rs. {item.amount/100}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.paymentStatus}</p>
                                    </div>
                                </td>

                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
        {/* { showCreate.status ? <ManageProduct onClose={setShowCreate} onSubmit={onSubmit} edit={showCreate.edit}/> : null} */}
    </>
  )
}

